.primary-text-light-color {
    color: var(--primary-text-light-color) !important;
}
.primary-text-dark-color {
    color: var(--primary-text-dark-color) !important;
}
.primary-color {
    background-color: var(--primary-text-light-color) !important;
}
.secondary-color {
    background-color: var(--secondary-background-color) !important;
}
body {
    font-family: "Poppins", Arial, sans-serif;
    font-size: 13px;
    line-height: 1.8;
    font-weight: 400;
    background-color: #b6b7be;
    color: grey;
}

.wallpaper {
    background-color: #f1f1f1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #565665;
}

.wallpaper > div#app {
    backdrop-filter: blur(5px);
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

@media only screen and (max-width: 992px) {
    .group-header{
        position: initial;
        top: auto;
    }
}
.flexbox {
    display: flex;
    flew-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.flexbox > div.flexbox-content > div.card {
    min-width: 350px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px !important;
}
.flexbox div.flexbox-content > div > form > div.card-content{
    padding-top: 32px !important;
    padding-bottom: 32px !important;
}

.flexbox div.flexbox-content > div > form > div.card-content > span.card-title{
    padding-bottom: 10px !important;
}
/*CHIPS*/
.ship-customer-sector-home{
    font-size: 13px;
    line-height: 1.4rem;
    font-weight: 400;
    color: #616161 !important;
    box-sizing: inherit;
    display: flex !important;
    justify-content: start !important;
    width: 100% !important;
    height: 100px!important;
    padding: 10px !important;
    background-color: rgb(100 100 100 / 20%);
    border-radius: 6px;
}
.ship-customer-cover{
    font-weight: 400;
    background-color: #fff !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px !important;
    border-radius: 8px !important;
    padding: 6px;
}


/*DISPLAY FLEX*/
.d-flex {
    display: flex !important;
}
.flex-column {
    flex-direction: column !important;
}
.flex-row {
    flex-direction: row !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-end {
    justify-content: end !important;
}

.justify-content-start {
    justify-content: start !important;
}

.justify-content-center {
    justify-content: center !important;
}
.align-items-center {
    justify-items: center !important;
}

/*BADGES*/
span.badge {
    min-width: 1.8rem;
    padding: 0 3px;
    margin-left: 14px;
    text-align: center;
    font-size: 1rem;
    line-height: 22px;
    height: 22px;
    color: #757575;
    float: right;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 4px;
}


/*NAV*/
nav .brand-logo {
    position: absolute;
    color: #fff;
    display: flex;
    font-size: 1.5rem;
    padding: 0;
}
nav .brand-logo span {
    padding-left: 4px;
}

@media only screen and (max-width: 700px) {
    nav .brand-logo i.material-icons{
        display: none;
    }
}

div.navbar-fixed > nav {
    padding-left: 270px;
    padding-right: 20px;
    background-color: #fff;
    color: #343434 !important;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

nav a {
    color: #343434 !important;
}


/*SIDENAV*/
header,
main,
footer {
    padding-left: 250px;
}

@media only screen and (max-width: 992px) {
    div.navbar-fixed > nav,
    header,
    main,
    footer {
        padding-left: 0;
    }
    main{
        margin-bottom: 50px;
    }
    .toolbar-mobile {
        position: fixed;
        bottom: 10px;
        background: #FFF;
        width: calc(100% - 20px);
        padding: 0 10px;
        margin: 0 10px;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px !important;
        border: 0.01rem solid rgba(100,100,100, 0.3);
    }
}

.sidenav {
    background-image: var(--primary-background-color);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    height: 100vh;
    width: 240px;
    padding-bottom: 0;
}

.sidenav::-webkit-scrollbar-track {
    background-color: transparent;
}

.sidenav::-webkit-scrollbar {
    width: 10px;
}

.sidenav::-webkit-scrollbar-thumb {
    background-color: rgb(220, 220, 220, .1);
}
.sidenav li {
    position: relative;
    z-index: 1001 !important;
}
.sidenav li > a {
    color: rgba(255, 255, 255, 0.87);
    display: block;
    font-size: 12px;
    font-weight: 500;
    height: 48px;
    line-height: 48px;
    padding: 0 32px;
}

.sidenav li > a:hover {
    background-color: rgba(0, 0, 0, 0.15) !important;
}

.sidenav li > a > i {
    color: rgba(255, 255, 255, 0.87) !important;
    height: 48px;
    line-height: 48px;
    margin: 0 12px 0 0 !important;
    width: 24px;
}

.sidenav-logo > div {
    position: fixed;
    top: 50%;
    transform: translateY(-90%);
    width: 100%;
    align-items: center;
    display: flex !important;
    justify-content: center;
    color: rgba(255, 255, 255, 0.87);
    font-size: 16px;
    font-weight: 700;
    height: 128px;
    line-height: 98px;
    padding: 0 32px;
    z-index: 998;
}

.sidenav-logo > div > img {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 150px !important;
    height: 150px !important;
    opacity: 0.1;
}

.sidenav li.active {
    background-color: rgba(0, 0, 0, 0.12);
}
.sidenav li.id-city{
    background: transparent;
    color: #282828;
    position: sticky;
    top: 0;
    bottom: calc(100vh - 85px) !important;
    width: 100%;
    z-index: 1100!important;
    padding: 10px;
}
.sidenav li.id-city a{
    background: white;
    align-items: center;
    color: #282828;
    display: flex;
    font-size: .9rem;
    height: 65px;
    line-height: 65px;
    padding: 0 10px;
    text-transform: uppercase;
    border-radius: 6px;
}
.sidenav li.id-city a:hover{
    background: rgba(255,255,255,1) !important;
}
.sidenav li.id-city a div.content{
    line-height: 1rem;
}
.sidenav li.id-city a div.content span.title{
    font-size: 0.9rem;
    line-height: 1.4rem;
}
.sidenav li.id-city a div.content div.subtitle{
    font-size: 0.7rem;
    line-height: 0.6rem;
}
.sidenav li.id-city a img{
    height: 34px;
    margin-right: 0.6rem;
}


.sidenav .collapsible-header, .sidenav.fixed .collapsible-header {
    background-color: transparent;
    border: none;
    line-height: inherit;
    height: inherit;
    padding: 0 32px;
}

.sidenav .collapsible-body, .sidenav.fixed .collapsible-body {
    border: 0;
    background-color: rgba(245, 225, 225, 0.01) !important;
}

.sidenav .collapsible-body > ul:not(.collapsible) > li.active, .sidenav.sidenav-fixed .collapsible-body > ul:not(.collapsible) > li.active {
    background-color: rgba(245, 225, 225, 0.2) !important;
    color: #121212 !important;
}
.sidenav .collapsible-body li a, .sidenav.fixed .collapsible-body li a {
    padding: 0 23.5px 0 37px;
}
.keyboard-focused .collapsible-header:focus{
    background-color: rgba(255, 255, 255, 0.4) !important;
}

/** TOOLTIPS */
.material-tooltip {
    padding: 5px 10px;
    font-size: 0.5rem;
    border-radius: 6px;
    color: #fff;
    min-height: 24px;
    line-height: inherit;
    text-align: center;
    max-width: calc(100% - 4px);
    pointer-events: none;
    visibility: hidden;
    background-color: #323232;
}
/** CAROUSEL */
.carousel .indicators .indicator-item {
    display: inline-block;
    position: relative;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin: 24px 4px;
    background-color: rgb(158 157 157 / 50%);
    -webkit-transition: background-color .3s;
    transition: background-color .3s;
    border-radius: 50%;
}
.carousel .indicators .indicator-item.active {
    background-color: #000;
}
/*COLLAPSIBLE*/
.collapsible{
    box-shadow: none;
    background: transparent;
    border: none;
}
.collapsible li div.collapsible-header{
    border-radius: 0.5rem;
    border: none;
}
.collapsible li div.collapsible-body{
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    padding-bottom: 0.5rem;
    background: rgba(250,250,250,0.5);
    overflow: auto;
}
/*TABS*/
.tabs {
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    height: auto !important;
    width: 100%;
    background-color: transparent;
    margin: 0 auto;
    white-space: nowrap;
}

.tabs .tab {
    display: inline-block;
    text-align: center;
    line-height: 20px;
    height: auto !important;
    padding: 0;
    margin: 0.6rem 0.8rem 0.6rem 0.8rem;
    text-transform: uppercase;
    max-width: 15rem;
}

.tabs .tab a {
    color: var(--secondary-text-color);
    background-color: rgba(255,255,255,0.5) !important;
    border-radius: 8px;
    display: grid;
    width: 100%;
    padding: 10px 10px;
    font-size: 11px;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-transition: color .28s ease, background-color .28s ease;
    transition: color .28s ease, background-color .28s ease;
}
.tabs .tab.disabled a, .tabs .tab.disabled a:hover {
    color: rgba(100, 100 , 100, 0.3);
    cursor: default;
}
.tabs .tab a:hover {
    background-color: white !important;
    color: var(--secondary-text-color);
}

.tabs .tab a.active {
    background-color: var(--secondary-text-color) !important;
    color: rgba(255,255,255,0.9) !important;
    padding: 10px 10px;
    line-height: 20px;
}

.tabs .indicator {
    position: absolute;
    bottom: 0;
    height: 0;
    background-color: var(--primary-text-dark-color);
    will-change: left, right;
}
.card-loader {
    background: transparent;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1 !important;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card-loader > div.progress {
    position: relative;
    height: 4px;
    display: block;
    width: 100%;
    background-color: var(--secondary-background-color);
    border-radius: 2px;
    margin: 0.5rem 0 1rem 0;
    overflow: hidden;
}
.card-loader > div.progress .indeterminate {
    background-color: var(--secondary-text-color);
}

/**/
.collection {
    border: none;
}
.collection .collection-item.avatar {
     min-height: 68px;
     padding-left: 0;
     position: relative;
 }
.collection .collection-item {
    background-color: #fff;
    line-height: 1.5rem;
    padding: 3px 5px;
    margin: 0;
    border-bottom: none;
}

/*CARDS*/
.card.tiny {
    height: 200px;
}
.card.stiny {
    height: 150px;
}
.card-motorcycle-sticky{
    position: sticky !important;
    top: 135px;
}
.card,
.card-panel,
.card-app{
    border-radius: 6px !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px !important;
}
.card-action{
    border-radius: 0 0 6px 6px !important;
}
.z-depth-custom{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px !important;
}

.card-custom {
    padding: 16px;
    border-radius: 6px;
}
.card-motorcycle .card-image .card-title {
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding: 3px 11px;
    background: rgba(30,30,30, 0.6);
    width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-action-custom {
    float: right !important;
}

.card-action-custom > div.card-action-container {
    float: right;
}

.card-content-column {
    padding-top: 6px !important;
}

.card-app{
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    width: 100%;
    border-radius: 6px;
}
.card-app.card-schedule-manager{
    display: block;
}

.card-app.card-schedule-manager > div.card-app-content > div.card-app-body{
    height: calc(100vh - 320px);
    max-height: calc(100vh - 320px);
    overflow-y: auto;
}
.card-app.card-schedule-manager > div.card-app-content > div.card-app-nav{
    position: sticky;
    top: 0;
    z-index: 1 !important;
}
.schedule-title-content{
    position: sticky; top: 0;
}
.schedule-table-content{
    border: 0.05rem solid #cdcdcd;
    border-radius: 8px;
    height: 294px;
    max-height: 294px;
    overflow: auto;
}
.carousel.carousel-slider .carousel-fixed-item {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    z-index: 1;
    background: rgba(30, 30, 30, 0.6);
    padding: 16px;
    width: auto !important;
    margin-right: 40px;
    margin-left: 40px;
    border-radius: 8px;
}
.carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: auto !important;
    position: absolute;
    top: 0;
    left: 0;
}
.carousel .indicators .indicator-item.active {
    background-color: var(--secondary-text-color);
}
.carousel .indicators .indicator-item {
    height: 15px;
    width: 15px;
    background-color: rgb(255 255 255 / 81%);
    transition: background-color 0.3s;
    border-radius: 50%;
}

.card-app-content{
    display: flex;
    width: 100%;
}
.card-app-content.horizontal{
    display: flex;
    flex-direction: column !important;
}

@media only screen and (max-width: 992px) {
    div.card-app-content.adapter{
        flex-direction: column;
    }

    .card-motorcycle-sticky{
        position: sticky !important;
        top: auto;
    }

    div.card-app-content.adapter div.card-app-nav{
        max-width: 100% !important;
        width: auto !important;
        height: 4.5rem !important;
        flex-direction: column;
        border-radius: 6px 6px 0 0 !important;
    }

    div.card-app-content.adapter div.card-app-nav div ul.tabs{
        flex-direction: row !important;
    }
}

.card-app-nav{
    background-color: rgba(255, 255,255, 1);
    position: relative;
    max-width: 10rem;
    height: 100%;
    border-radius: 6px 0 0 6px;
    border-right: 0.02rem solid rgba(140, 140, 140, 0.2);
}
.card-app-content.horizontal > div.card-app-nav{
    max-width: initial !important;
}
.card-app-body{
    width: 100%;
}
.card-number{
    border-radius: 8px;
    border: 0.004rem solid #ababab;
    background: #fafafa;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
}
.card-number > span.number{
    font-size: 3rem;
}
.card-app-header{
    display: block;
    width: 100%;
    background-color: rgba(255, 255,255, 1);
    border-bottom: 0.02rem solid rgba(140, 140, 140, 0.2);
    padding: 0.4rem;
}

/*DROPDOWN AND SELECTS*/
.dropdown-content li > a, .dropdown-content li > span {
    font-size: 16px;
    color: #353535;
    display: block;
    line-height: 22px;
    padding: 14px 16px;
}

.dropdown-content {
    background-color: #fff;
    margin: 0;
    display: none;
    min-width: 100px;
    overflow-y: auto;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9999;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 6px;
    border: 1px solid rgba(100, 100, 111, 0.2);
}

.dropdown-content li > a > i {
    height: inherit;
    line-height: inherit;
    float: left;
    margin: 0 2px 0 0;
    width: 24px;
}

.select-wrapper input.select-dropdown:focus {
    border-bottom: 1px solid var(--primary-text-light-color);
}

.card .card-action a:not(.btn):not(.btn-large):not(.btn-small):not(.btn-large):not(.btn-floating) {
    color: #353535;
    margin-right: 24px;
    -webkit-transition: color .3s ease;
    transition: color .3s ease;
    text-transform: uppercase;
}

.card .card-action a:not(.btn):not(.btn-large):not(.btn-small):not(.btn-large):not(.btn-floating):hover {
    color: #121212;
}

/*INPUTS*/
input:not([type]):focus:not([readonly]), input[type=text]:not(.browser-default):focus:not([readonly]), input[type=password]:not(.browser-default):focus:not([readonly]), input[type=email]:not(.browser-default):focus:not([readonly]), input[type=url]:not(.browser-default):focus:not([readonly]), input[type=time]:not(.browser-default):focus:not([readonly]), input[type=date]:not(.browser-default):focus:not([readonly]), input[type=datetime]:not(.browser-default):focus:not([readonly]), input[type=datetime-local]:not(.browser-default):focus:not([readonly]), input[type=tel]:not(.browser-default):focus:not([readonly]), input[type=number]:not(.browser-default):focus:not([readonly]), input[type=search]:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
    border-bottom: 1px solid var(--primary-text-light-color);
    -webkit-box-shadow: 0 1px 0 0 var(--primary-text-light-color);
    box-shadow: 0 1px 0 0 var(--primary-text-light-color);
}
nav.toolbar-search {
    color: #090909;
    background-color: #f5f5f5;
    width: 100%;
    height: 64px;
    line-height: 64px;
    border-radius: 8px;
}

nav.toolbar-search .input-field{
    height: 100% !important;
}
nav.toolbar-search .input-field label i {
    color: #090909;
    -webkit-transition: color .3s;
    transition: color .3s;
}

nav div.nav-wrapper .input-field input[type=search] {
    border-radius: 8px;
}
nav div.nav-wrapper .input-field input[type=search]:not(.browser-default):focus:not([readonly]){
    border-bottom: 0;
    box-shadow: none;
}

[type="checkbox"].filled-in:checked+span:not(.lever):after {
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid var(--primary-text-light-color);
    background-color: var(--primary-text-light-color);
    z-index: 0;
}
[type="checkbox"].filled-in:not(:checked)+span:not(.lever):after {
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 2px solid #a8a8a8;
    top: 0px;
    z-index: 0;
}

input:not([type]):focus:not([readonly]) + label, input[type=text]:not(.browser-default):focus:not([readonly]) + label, input[type=password]:not(.browser-default):focus:not([readonly]) + label, input[type=email]:not(.browser-default):focus:not([readonly]) + label, input[type=url]:not(.browser-default):focus:not([readonly]) + label, input[type=time]:not(.browser-default):focus:not([readonly]) + label, input[type=date]:not(.browser-default):focus:not([readonly]) + label, input[type=datetime]:not(.browser-default):focus:not([readonly]) + label, input[type=datetime-local]:not(.browser-default):focus:not([readonly]) + label, input[type=tel]:not(.browser-default):focus:not([readonly]) + label, input[type=number]:not(.browser-default):focus:not([readonly]) + label, input[type=search]:not(.browser-default):focus:not([readonly]) + label, textarea.materialize-textarea:focus:not([readonly]) + label {
    color: var(--primary-text-light-color);
}
.input-field .prefix.active {
    color: var(--primary-text-light-color);
}
input.invalid{
    background-color: #ffcdd2 !important;
    color: #c62828 !important;
}
input.valid{
    background-color: #c8e6c9 !important;
    color: #2e7d32 !important;
}
input.invalid::placeholder {
    color: #c62828;
}
input.invalid::-ms-input-placeholder {
    color: #c62828;
}
input.invalid::-ms-input-placeholder {
    color: #c62828;
}
input.valid::placeholder {
    color: #2e7d32;
}
input.valid::-ms-input-placeholder {
    color: #2e7d32;
}
input.valid::-ms-input-placeholder {
    color: #2e7d32;
}
.input-field img.prefix{
    position: absolute;
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem;
    -webkit-transition: color .2s;
    transition: color .2s;
    top: 0.5rem;
}

/*BUTTONS*/
//PRIMARY
.btn-default-size{
    height: 36px;
}
.btn.primary, .btn-large.primary, .btn-floating.primary, .btn-small.primary {
    background-color: var(--secondary-background-color) !important;
    color: var(--secondary-text-color) !important;
    border-radius: 6px;
    box-shadow: none;
}

.btn:focus.primary, .btn-large:focus.primary, .btn-small:focus.primary, .btn-floating:focus.primary {
    background-color: var(--secondary-background-color) !important;
    color: var(--secondary-text-color) !important;
    border-radius: 6px;
    box-shadow: none;
}

.btn:hover.primary, .btn-large:hover.primary, .btn-floating:hover.primary, .btn-small:hover.primary {
    background-color: var(--secondary-background-color) !important;
    color: var(--secondary-text-color) !important;
    border-radius: 6px;
    box-shadow: none;
}

//SECONDARY
.btn.secondary, .btn-large.secondary, .btn-floating.secondary, .btn-small.secondary {
    background-color: var(--secondary-background-color) !important;
    color: var(--secondary-text-color) !important;
    border-radius: 6px;
    box-shadow: none;
}

.btn:focus.secondary, .btn-large:focus.secondary, .btn-floating:focus.secondary, .btn-small:focus.secondary, .btn-floating:focus.secondary {
    background-color: var(--secondary-background-color) !important;
    color: var(--secondary-text-color) !important;
    border-radius: 6px;
    box-shadow: none;
}

.btn:hover.secondary, .btn-large:hover.secondary, .btn-floating:hover.secondary, .btn-small:hover.secondary {
    background-color: var(--secondary-background-color) !important;
    color: var(--secondary-text-color) !important;
    border-radius: 6px;
    box-shadow: none;
}


//DEFAULT
.btn i.left {
    margin-right: 5px;
}

.btn, .btn-large, .btn-small, .btn-floating {
    background-color: var(--secondary-background-color) !important;
    color: var(--primary-text-light-color);
    border-radius: 6px;
    box-shadow: none;
    padding: 0 12px;
}

.btn:focus, .btn-large:focus, .btn-small:focus, .btn-floating:focus, .btn-floating:focus {
    background-color: #FAFAFA;
    color: #343434;
    box-shadow: none;
}

.btn:hover, .btn-large:hover, .btn-floating:hover, .btn-small:hover {
    background-color: var(--secondary-text-color);
    border-radius: 6px;
    box-shadow: none;
}


/*MODALS*/

.modal-sm {
    width: 400px !important;
    max-width: 400px !important;
}

@media only screen and (max-width: 668px) {
    .modal-sm {
        max-width: 80% !important;
    }
}

.modal {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    background-color: #fafafa;
    padding: 0;
    max-height: 70%;
    width: 55%;
    margin: auto;
    overflow-y: auto;
    will-change: top, opacity;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px !important;
}

.modal .modal-footer {
    border-radius: 0 0 2px 2px;
    background-color: #fafafa;
    padding: 2px 24px;
    height: 66px;
    width: 100%;
    text-align: right;
}

.modal-footer > .btn i,
.modal-footer > .btn-small i,
.modal-footer > .btn-floating i,
.modal-footer > .btn-large i,
.modal-footer > .btn-flat i {
    font-size: 1.3rem;
    line-height: inherit;
    height: inherit;
}

.modal-footer > .btn > i.left,
.modal-footer > .btn-large > i.left,
.modal-footer > .btn-small > i.left,
.modal-footer > .btn-floating > i.left,
.modal-footer > .btn-flat > i.left {
    float: left;
    margin-right: 5px;
}

.modal-content > div.row > div.col {
    line-height: 24px !important;
}
.modal-content-plain{
    padding: 0 !important;
}
.modal-filter{
    overflow: hidden;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.modal-overlay {
    background: #fff !important;
    opacity: 0.85 !important;
}
/*SIZES*/
.w-100 {
    width: 100% !important;
}

/*LINE HEIGHT*/
.mt-0 {
    margin-top: 0 !important;
}
.line-height-1{
    line-height: 1.2rem;
}

.line-height-2{
    line-height: 1.5rem;
}

.line-height-3{
    line-height: 1.8rem;
}

.line-height-4{
    line-height: 2rem;
}

.line-height-5{
    line-height: 2.5rem;
}

/*BORDER RADIUS*/
.no-border {
    border: none !important;
}

/*BORDER RADIUS*/
.border-radius-2 {
    border-radius: 2px !important;
}

.border-radius-4 {
    border-radius: 4px !important;
}

.border-radius-6 {
    border-radius: 6px !important;
}

.border-radius-8 {
    border-radius: 8px !important;
}

.border-radius-10 {
    border-radius: 10px !important;
}

.border-radius-12 {
    border-radius: 12px !important;
}

.border-radius-14 {
    border-radius: 14px !important;
}

.border-radius-16 {
    border-radius: 16px !important;
}

.border-radius-18 {
    border-radius: 18px !important;
}

/*MARGINS AND PADDINGS*/
.mt-0 {
    margin-top: 0 !important;
}

.mt-1 {
    margin-top: 1% !important;
}

.mt-2 {
    margin-top: 2% !important;
}

.mt-3 {
    margin-top: 3% !important;
}

.mt-4 {
    margin-top: 4% !important;
}

.mt-5 {
    margin-top: 5% !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 1% !important;
}

.mb-2 {
    margin-bottom: 2% !important;
}

.mb-3 {
    margin-bottom: 3% !important;
}

.mb-4 {
    margin-bottom: 4% !important;
}

.mb-5 {
    margin-bottom: 5% !important;
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.my-1 {
    margin-top: 1% !important;
    margin-bottom: 1% !important;
}

.my-2 {
    margin-top: 2% !important;
    margin-bottom: 2% !important;
}

.my-3 {
    margin-top: 3% !important;
    margin-bottom: 3% !important;
}

.my-4 {
    margin-top: 4% !important;
    margin-bottom: 4% !important;
}

.my-5 {
    margin-top: 5% !important;
    margin-bottom: 5% !important;
}


.ml-0 {
    margin-left: 0 !important;
}

.ml-1 {
    margin-left: 1% !important;
}

.ml-2 {
    margin-left: 2% !important;
}

.ml-3 {
    margin-left: 3% !important;
}

.ml-4 {
    margin-left: 4% !important;
}

.ml-5 {
    margin-left: 5% !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mr-1 {
    margin-right: 1% !important;
}

.mr-2 {
    margin-right: 2% !important;
}

.mr-3 {
    margin-right: 3% !important;
}

.mr-4 {
    margin-right: 4% !important;
}

.mr-5 {
    margin-right: 5% !important;
}

.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.mx-1 {
    margin-left: 1% !important;
    margin-right: 1% !important;
}

.mx-2 {
    margin-left: 2% !important;
    margin-right: 2% !important;
}

.mx-3 {
    margin-left: 3% !important;
    margin-right: 3% !important;
}

.mx-4 {
    margin-left: 4% !important;
    margin-right: 4% !important;
}

.mx-5 {
    margin-left: 5% !important;
    margin-right: 5% !important;
}


.pt-0 {
    padding-top: 0 !important;
}

.pt-1 {
    padding-top: 1% !important;
}

.pt-2 {
    padding-top: 2% !important;
}

.pt-3 {
    padding-top: 3% !important;
}

.pt-4 {
    padding-top: 4% !important;
}

.pt-5 {
    padding-top: 5% !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pb-1 {
    padding-bottom: 1% !important;
}

.pb-2 {
    padding-bottom: 2% !important;
}

.pb-3 {
    padding-bottom: 3% !important;
}

.pb-4 {
    padding-bottom: 4% !important;
}

.pb-5 {
    padding-bottom: 5% !important;
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.py-1 {
    padding-top: 1% !important;
    padding-bottom: 1% !important;
}

.py-2 {
    padding-top: 2% !important;
    padding-bottom: 2% !important;
}

.py-3 {
    padding-top: 3% !important;
    padding-bottom: 3% !important;
}

.py-4 {
    padding-top: 4% !important;
    padding-bottom: 4% !important;
}

.py-5 {
    padding-top: 5% !important;
    padding-bottom: 5% !important;
}


.pl-0 {
    padding-left: 0 !important;
}

.pl-1 {
    padding-left: 1% !important;
}

.pl-2 {
    padding-left: 2% !important;
}

.pl-3 {
    padding-left: 3% !important;
}

.pl-4 {
    padding-left: 4% !important;
}

.pl-5 {
    padding-left: 5% !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pr-1 {
    padding-right: 1% !important;
}

.pr-2 {
    padding-right: 2% !important;
}

.pr-3 {
    padding-right: 3% !important;
}

.pr-4 {
    padding-right: 4% !important;
}

.pr-5 {
    padding-right: 5% !important;
}


.px-1 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.px-1 {
    padding-left: 1% !important;
    padding-right: 1% !important;
}

.px-2 {
    padding-left: 2% !important;
    padding-right: 2% !important;
}

.px-3 {
    padding-left: 3% !important;
    padding-right: 3% !important;
}

.px-4 {
    padding-left: 4% !important;
    padding-right: 4% !important;
}

.px-5 {
    padding-left: 5% !important;
    padding-right: 5% !important;
}
